import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { FaHeart } from "react-icons/fa";
import Countdown from "react-countdown";

const Invite = () => {
	const { id } = useParams();

	const [loading, setLoading] = useState();
	const [guest, setGuest] = useState();

	const fetchGuest = async (id) => {
		try {
			setLoading(true);

			const { data } = await axios(`hurim/${id}/invite`);

			setGuest(data);
		} catch (error) {
			Swal.fire({
				icon: "warning",
				iconColor: "#11498b",
				text: "Дахин оролдоно уу",
			});
		} finally {
			setLoading(false);
		}
	};

	const setResponse = async (type) => {
		try {
			setLoading(true);

			await axios({
				url: `hurim/${id}`,
				method: "PUT",
				data: { type },
			});

			const ok = await Swal.fire({
				icon: "success",
				iconColor: "#11498b",
				html:
					type === "Баталгаажсан"
						? `Урилга хүлээн авч баталгаажуулсанд баярлалаа.<br/><br/>Таны суух ширээний дугаарыг хуримын өмнөх өдөр буюу 2024.08.03-нд цахимаар мэдээлэх болно.`
						: `Мэдээллийг хүлээн авлаа.`,
			});

			window.close();
		} catch (error) {
			Swal.fire({
				icon: "warning",
				iconColor: "#11498b",
				text: "Дахин оролдоно уу",
			});
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (id) fetchGuest(id);
	}, [id]);

	return (
		<div className="min-h-screen w-full flex relative items-center justify-center text-center">
			{loading && (
				<div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-white">
					<FaHeart className="animate-ping text-2xl"></FaHeart>
				</div>
			)}
			{guest && (
				<>
					<div className="fixed top-0 left-0 right-0 bottom-0 h-screen w-full flex">
						<img
							src="/icon3.png"
							className="opacity-10 object-contain mx-auto"
						/>
					</div>
					<div className="p-5 z-10">
						<div className="uppercase mb-10 text-3xl">ХУРИМЫН УРИЛГА</div>
						<div className="animate-fade-down">
							{guest.title} <b>{guest.name?.toUpperCase()}</b> таныг
						</div>
						<div className="animate-fade-down">
							{guest.relation} хуримын ёслолд хүрэлцэн ирэхийг урьж байна.
						</div>

						<div className="my-10 animate-fade-up">
							Хуримын ёслол Зайсан хилл комплексийн гуравдугаар давхарт Z'Terra
							ресторанд <b>2024.08.04</b> өдрийн <b>16:00</b> цагаас эхэлнэ.
						</div>
						<div className="animate-fade-down">
							<Countdown
								date={new Date("2024-08-04 16:00:00")}
								suppressHydrationWarning
								renderer={({ days, hours, minutes, seconds }) => (
									<div className="flex gap-5 justify-center items-end text-center">
										<div className="flex-1">
											<div className="text-5xl">{days}</div>
											<div className="">хоног</div>
										</div>
										<div className="flex-1">
											<div className="text-5xl">{hours}</div>
											<div className="">цаг</div>
										</div>
										<div className="flex-1">
											<div className="text-5xl">{minutes}</div>
											<div className="">минут</div>
										</div>
										<div className="flex-1">
											<div className="text-5xl" suppressHydrationWarning>
												{seconds}
											</div>
											<div className="">секунд</div>
										</div>
									</div>
								)}
							/>
						</div>
						<div className="flex flex-col gap-5 mt-10">
							<div className="text-xs">
								Доорх хоёр товчлуураас аль нэгийг сонгож дарна уу.
							</div>
							<button
								onClick={() => {
									setResponse("Баталгаажсан");
								}}
								className="p-2 border bg-primary-100 shadow-md text-white uppercase animate-fade-up"
							>
								Урилгыг хүлээн авч баталгаажуулах
							</button>
							<button
								onClick={() => {
									setResponse("Татгалзсан");
								}}
								className="p-2 border bg-white shadow-md animate-fade-down"
							>
								Татгалзах, ёслолд оролцох боломжгүйг мэдэгдэх
							</button>
						</div>

						<div className="text-xs mt-10">v1.1.0</div>
					</div>
				</>
			)}
		</div>
	);
};

export default Invite;
