import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import axios from "axios";
import "./index.css";

import Invite from "./components/Invite";
import Table from "./components/Table";
import Guests from "./components/Guests";

axios.defaults.auth = { username: "oogii", password: "Asdqwe123!" };
axios.defaults.baseURL =
	process.env.NODE_ENV === "development"
		? "http://localhost:3307/"
		: "https://ez-api.sys.mn/";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<Routes>
				<Route path="/i/:id" element={<Invite />}></Route>
				<Route path="/t/:id" element={<Table />}></Route>
				<Route path="/guests/control" element={<Guests />}></Route>
			</Routes>
		</BrowserRouter>
	</React.StrictMode>
);
