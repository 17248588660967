import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { FaChevronDown, FaHeart, FaPlay, FaVolumeMute } from "react-icons/fa";
import Countdown from "react-countdown";
import Animated from "./Animated";
import { Element, Link } from "react-scroll";
import Plan from "./Plan";

const Table = () => {
	const { id } = useParams();

	const [loading, setLoading] = useState();
	const [guest, setGuest] = useState();

	const [accept, setAccept] = useState(false);

	const player = useRef();

	const [playing, setPlaying] = useState(true);

	const playPause = () => {
		if (playing) player.current.pause();
		else player.current.play();

		setPlaying(!playing);
	};

	const fetchGuest = async (id) => {
		try {
			setLoading(true);

			const { data } = await axios(`hurim/${id}/table`);

			setGuest(data);
		} catch (error) {
			Swal.fire({
				icon: "warning",
				iconColor: "#11498b",
				text: "Дахин оролдоно уу",
			});
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (id) fetchGuest(id);
	}, [id]);

	return (
		<div className="min-h-screen w-full flex relative items-center justify-center text-center">
			{loading && (
				<div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-white">
					<FaHeart className="animate-ping text-2xl"></FaHeart>
				</div>
			)}
			{guest &&
				(!accept ? (
					<>
						<div className="fixed top-0 left-0 right-0 bottom-0 h-screen w-full flex">
							<img
								src="/icon3.png"
								className="opacity-10 object-contain mx-auto"
							/>
						</div>
						<div className="p-5 z-10">
							<div className="animate-fade-down">
								{guest.title} <b>{guest.name?.toUpperCase()}</b> та
							</div>
							<div className="animate-fade-down">
								<b>
									{guest.table === "99" ? "ТОЛГОЙ" : `${guest.table} дугаарын`}
								</b>{" "}
								ширээнд тухлан сууна уу.
							</div>
							<div className="flex flex-col gap-5 mt-10">
								<button
									onClick={() => {
										setAccept(true);
									}}
									className="p-2 border bg-primary-100 shadow-md text-white uppercase animate-fade-up"
								>
									ШИРЭЭНИЙ БАЙРЛАЛ ХАРАХ
								</button>
							</div>
						</div>
					</>
				) : (
					<div className="w-full">
						<audio
							controls={true}
							autoPlay={true}
							className="hidden"
							loop={true}
							ref={player}
						>
							<source src="/audio.mp3" type="audio/mp3" />
						</audio>
						<div className="relative mx-5 my-12">
							<img
								src="/w_couple.svg"
								className="animate-fade w-auto mx-auto max-h-screen"
							/>
							<div className="absolute top-10 left-0 w-full flex flex-col items-center">
								<Animated className="-ml-16" animation="animate-fade-left">
									<img className="object-contain h-12" src="/label.png" />
								</Animated>
								<Animated
									className="-ml-10 md:-ml-32"
									animation="animate-fade-right"
								>
									<img className="object-contain h-24" src="/ganjiguur.png" />
								</Animated>
								<Animated className=" -mt-4" animation="animate-fade-left">
									<img className="object-contain h-24" src="/enkhbat.png" />
								</Animated>
								<Animated className="-ml-16" animation="animate-fade-right">
									<img className="object-contain h-12" src="/date.png" />
								</Animated>
							</div>
							<div
								className="fixed bg-white right-5 top-5 z-10 w-10 h-10 border shadow-md rounded-full flex items-center justify-center cursor-pointer"
								onClick={() => playPause()}
							>
								{playing ? <FaVolumeMute></FaVolumeMute> : <FaPlay></FaPlay>}
							</div>
							<Link
								to="extra"
								smooth={true}
								spy={true}
								offset={-64}
								className="absolute bg-white bottom-2 left-0 w-16 h-16 border shadow-md rounded-full flex items-center justify-center animate-bounce cursor-pointer"
							>
								<FaChevronDown />
							</Link>
						</div>
						<Animated animation="animate-fade-down">
							<Countdown
								date={new Date("2024-08-04 16:00:00")}
								suppressHydrationWarning
								renderer={({ days, hours, minutes, seconds }) => (
									<div className="flex gap-5 justify-center items-end text-center">
										<div className="flex-1">
											<div className="text-5xl">{days}</div>
											<div className="">хоног</div>
										</div>
										<div className="flex-1">
											<div className="text-5xl">{hours}</div>
											<div className="">цаг</div>
										</div>
										<div className="flex-1">
											<div className="text-5xl">{minutes}</div>
											<div className="">минут</div>
										</div>
										<div className="flex-1">
											<div className="text-5xl" suppressHydrationWarning>
												{seconds}
											</div>
											<div className="">секунд</div>
										</div>
									</div>
								)}
							/>
						</Animated>
						<Element name="extra">
							<div className="min-h-screen my-5">
								<Animated animation="animate-fade-up mt-10 p-2 text-sm">
									15:30 цагаас Z'Terra рестораны терас дээр зочдыг хүлээн авч
									эхлэх бөгөөд ёслол <b>16:00</b>-д эхэлнэ.
								</Animated>
								<Animated animation="animate-fade-down">
									<Plan table={guest.table}></Plan>
								</Animated>
								<Animated animation="animate-fade-up">
									<div className="flex items-center justify-center gap-5 p-5 bg-primary-100 mt-12">
										<img
											className="object-contain h-12"
											src="/zterra_white.png"
										/>
										<div className="text-white text-sm">
											Хан-Уул дүүрэг Зайсан Хилл комплексийн гуравдугаар давхарт
										</div>
									</div>
								</Animated>
								<Animated animation="animate-fade-down">
									<iframe
										src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2675.6211947128427!2d106.91337227695426!3d47.885660871216814!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5d969398c4e1ba29%3A0x16f739323bf2ad21!2sZ&#39;Terra%20Restaurant%20Event-Hall!5e0!3m2!1sen!2smn!4v1716190747193!5m2!1sen!2smn"
										height="450"
										className="w-full"
										allowFullScreen=""
										loading="lazy"
										referrerPolicy="no-referrer-when-downgrade"
									></iframe>
								</Animated>
							</div>
						</Element>
					</div>
				))}
		</div>
	);
};

export default Table;
