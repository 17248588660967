import Animated from "./Animated";

const TABLES = {
	1: { top: `24.3%`, left: "5.9%" },
	2: { top: `24.3%`, left: "17.9%" },
	3: { top: `38%`, left: "5.9%" },
	4: { top: `38%`, left: "17.9%" },
	5: { top: `51.6%`, left: "5.9%" },
	6: { top: `51.6%`, left: "17.9%" },
	7: { top: `65.4%`, left: "5.9%" },
	8: { top: `65.4%`, left: "17.9%" },
	9: { top: `85.7%`, left: "4.9%" },
	10: { top: `85.7%`, left: "16.6%" },
	11: { top: `85.7%`, left: "28.2%" },
	12: { top: `85.7%`, left: "76.2%" },
	// 13: { top: `64.4%`, left: "40.1%" },
	// 14: { top: `64.4%`, left: "52.1%" },
	// 15: { top: `64.4%`, left: "64.3%" },
	13: { top: `64.4%`, left: "79.6%" },
	// 17: { top: `51.7%`, left: "89.6%" },
	99: { top: `85%`, left: "52.3%" },
};

const Plan = ({ table }) => {
	const tableNumber = table;

	return (
		<div className="p-2">
			<Animated>
				<div className="text-center flex items-start justify-center gap-1 text-sm p-2">
					Ресторан дотор таны суух ширээний байрлал улаан өнгөөр анивчиж байгааг
					харна уу.
				</div>
			</Animated>
			<div className="relative">
				{TABLES[tableNumber] && (
					<div
						className="absolute bg-red-500 rounded-full animate-ping"
						style={{ ...TABLES[tableNumber], width: `4%`, height: `5%` }}
					></div>
				)}
				<img className="w-full" src="/plan-1.png" />
			</div>
		</div>
	);
};

export default Plan;
