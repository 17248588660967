import { useInView } from "react-intersection-observer";

const Animated = ({
	children,
	animation = "animate-fade-up",
	className,
	...props
}) => {
	const { ref, inView } = useInView({
		threshold: 0.2,
		...props,
	});

	return (
		<div
			ref={ref}
			className={`${
				inView ? animation : "opacity-0"
			} animate-duration-1500 animate-delay-100 ${className}`}
		>
			{children}
		</div>
	);
};

export default Animated;
