import { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";

const DOMAIN =
	process.env.NODE_ENV === "development"
		? "http://localhost:3000"
		: "https://hurim.info";

const LETTERS = {
	А: "A",
	Б: "B",
	В: "V",
	Г: "G",
	Д: "D",
	Е: "YE",
	Ё: "YO",
	Ж: "J",
	З: "Z",
	И: "I",
	Й: "I",
	К: "K",
	Л: "L",
	М: "M",
	Н: "N",
	О: "O",
	Ө: "U",
	П: "P",
	Р: "R",
	С: "S",
	Т: "T",
	У: "U",
	Ү: "U",
	Ф: "F",
	Х: "KH",
	Ц: "TS",
	Ч: "CH",
	Ш: "SH",
	Щ: "SHCH",
	Ъ: "",
	Ь: "I",
	Ы: "Y",
	Э: "E",
	Ю: "YU",
	Я: "YA",
};

const translateName = (input) => {
	try {
		let result = "";

		for (let i = 0; i < input.length; i++) {
			let tmp = LETTERS[input[i].toUpperCase()];
			result += tmp !== undefined ? tmp : input[i];
		}

		return result;
	} catch {
		return input;
	}
};

const Guests = () => {
	const [guests, setGuests] = useState([]);
	const [loading, setLoading] = useState(false);
	const [fetch, setFetch] = useState();

	const fetchGuests = async () => {
		try {
			setLoading(true);

			const { data } = await axios(`hurim/guest/all`);
			setGuests(data);
		} catch (error) {
			Swal.fire({
				icon: "success",
				iconColor: "#11498b",
				text: error.toString(),
			});
		} finally {
			setLoading(false);
		}
	};

	const send = async (type, code, phone, name, link, table) => {
		if (!phone || phone === "-")
			return Swal.fire({
				icon: "warning",
				iconColor: "#11498b",
				text: "Утасны дугааргүй байна",
			});

		try {
			setLoading(true);
			let text = `${translateName(
				name.toUpperCase()
			)} tanyg B.Ganjiguur P.Enkhbat-n hurimd hureltsen irehiig urij bn. Tsahim URILGA-iig ${link} link dr darj uzne uu. Bayrlalaa.`;

			if (type === "table") {
				text = `${translateName(name.toUpperCase())} ta ${
					table === "99" ? "tolgoi" : `${table} dugaariin`
				} shireend suuna uu. Shireenii barilaliig ${link} link dr darj uzne uu. Bayrlalaa.`;
			}

			await axios({
				url: `hurim/send/${code}/${type}`,
				method: "PUT",
				data: {
					phone,
					text,
				},
			});

			setFetch(Date.now());
		} catch (error) {
			console.log(error);
			Swal.fire({
				icon: "success",
				iconColor: "#11498b",
				text: error.toString(),
			});
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchGuests();
	}, [fetch]);

	return (
		<div className="p-5 guests w-full overflow-x-auto relative">
			{loading && (
				<div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-white">
					Ачааллаж байна
				</div>
			)}
			<table className="w-full table-auto">
				<thead>
					<tr>
						<td>№</td>
						<td>Ширээ</td>
						<td>Утас</td>
						<td>Нэр</td>
						<td>Урилга линк</td>
						<td className="w-128">Урилга дээрх текст</td>
						<td>Урилга линк илгээх</td>
						<td>Хариу</td>
						<td>Урилга линк дээр дарсан огноо</td>
						<td>Ширээний дугаар</td>
						<td>Ширээний линк</td>
						<td>Ширээний линк дээр дарсан огноо</td>
						<td>Ширээний линк илгээх</td>
					</tr>
				</thead>
				<tbody>
					{guests.map((guest, index) => {
						const {
							_id,
							title,
							relation,
							code,
							table,
							phone,
							name,
							invite_history,
							invite_seen,
							response,
							table_history,
							table_seen,
						} = guest;

						const invite_link = `${DOMAIN}/i/${code}`;
						const table_link = `${DOMAIN}/t/${code}`;

						return (
							<tr key={_id}>
								<td>{index + 1}</td>
								<td>{table === "99" ? "Толгой ширээ" : table}</td>
								<td className={phone ? "" : "bg-red-200"}>{phone}</td>
								<td>{name}</td>
								<td>
									<a target="_blank" href={invite_link}>
										{invite_link}
									</a>
								</td>
								<td>
									{title} <b>{name.toUpperCase()}</b> таныг {relation} хуримын
									ёслолд хүрэлцэн ирэхийг урьж байна.
								</td>
								<td>
									<button
										className={`border`}
										onClick={() => {
											send("invite", code, phone, name, invite_link);
										}}
									>
										Урилга илгээх ({invite_history?.length})
									</button>
								</td>
								<td
									className={
										!invite_seen
											? "bg-blue-200"
											: response === "Хариу өгөөгүй"
											? "bg-yellow-200"
											: response === "Татгалзсан"
											? "bg-red-200"
											: ""
									}
								>
									{!invite_seen ? "Линк нээгээгүй" : response}
								</td>
								<td>{invite_seen}</td>
								<td>{table === "99" ? "Толгой ширээ" : table}</td>
								<td>
									{table && (
										<a target="_blank" href={table_link}>
											{table_link}
										</a>
									)}
								</td>
								<td>{table_seen}</td>
								<td>
									{table && (
										<button
											className="p-2 border"
											onClick={() => {
												send("table", code, phone, name, table_link, table);
											}}
										>
											Ширээ илгээх ({table_history?.length})
										</button>
									)}
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
};

export default Guests;
